<template>
  <page-wrapper :isLoading="isLoading">
    <template #title>Tickets</template>
    <custom-table
      :pageNumber="currentPage"
      :count="count"
      :totalCount="totalCount"
      :tableItems="AllTickets"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #userInfo="data">
        <p>{{ data.item.userText.userName }}</p>
      </template>
      <template #type="data">
        <b-badge variant="primary" style="font-weight: normal !important">
          {{ ticketTypes[data.item.type] }}
        </b-badge>
      </template>
      <template #status="data">
        <b-badge :variant="data.item.isRead ? 'success' : 'secondary'">
          {{ data.item.isRead ? "Read" : "Unread" }}
        </b-badge>
      </template>
      <template #Detail="data">
        <b-link
          class="cursor-pointer"
          :to="`/apps/ticketDetail/${data.item.ticketId}`"
        >
          <feather-icon icon="EyeIcon" size="20" class="text-primary" />
        </b-link>
      </template>
    </custom-table>
  </page-wrapper>
</template>

<script>
import { BLink, BBadge } from "bootstrap-vue";
import { TicketGetAllRequest } from "@/libs/Api/Ticket";

export default {
  title: "Tickets List",
  name: "AllTickets",
  data() {
    return {
      ticketTypes: {
        0: "Public",
        1: "Authenticate",
        2: "Support",
        3: "Other",
      },
      isLoading: false,
      AllTickets: null,
      totalCount: null,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      CategoryName: "",
      TicketTypesId: [
        {
          option: "احراز هویت",
          value: 1,
        },
        {
          option: "پشتیبانی",
          value: 2,
        },
        {
          option: "سایر",
          value: 3,
        },
      ],
      TicketType: 0,
      myTableColumns: [
        {
          key: "ticketId",
          label: "ID",
        },
        {
          key: "title",
          label: "title",
        },
        {
          key: "userInfo",
          label: "Sender",
        },
        {
          key: "type",
          label: "type",
        },
        {
          key: "status",
          label: "status",
        },
        {
          key: "Detail",
          label: "show",
        },
      ],

      pageNumber: 1,
      count: 10,
      SelectedTicket: null,
    };
  },
  async created() {
    await this.GetAllTicket();
  },
  components: {
    BLink,
    BBadge,
    PageWrapper,
    CustomTable,
  },
  watch: {
    currentPage: function () {
      this.GetAllTicket();
    },
    TicketType: function () {
      this.GetAllTicket();
    },
  },
  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    async GetAllTicket() {
      try {
        this.isLoading = true;
        let _this = this;
        let ticketGetAllRequest = new TicketGetAllRequest(_this);
        let data = {
          pageNumber: _this.currentPage,
          count: _this.count,
        };
        ticketGetAllRequest.setParams(data);
        await ticketGetAllRequest.fetch(function (content) {
          _this.AllTickets = content.tickets;
          _this.totalCount = content.ticketCount;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
</script>

<style scoped></style>
